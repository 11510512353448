import { Timestamp } from "firebase/firestore";
import { PACIData, KYC, PersonalData } from "../types";
import { Children, ReactNode, isValidElement } from "react";
import { Highlight } from "@mantine/core";

export const getDaysDifference = (date1: Date, date2: Date): number => {
    // Get the difference in milliseconds
    const differenceInTime = date2.getTime() - date1.getTime();

    // Convert milliseconds to days (1000 ms * 60 s * 60 min * 24 hrs)
    const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);


    return Math.abs(Math.floor(differenceInDays)); // Use abs to ensure a positive result
}
export const isCorporateEmail = (email: string | undefined) => {
    if (!email) return false;
    const normalizedEmail = email.toLocaleLowerCase().trim();
    return normalizedEmail.endsWith('@kfhcapital.com.kw') || normalizedEmail.endsWith('@kfh.com');
};

export const dateFormater = (date?: Date, hideDay = false) => {
    const day = hideDay ? undefined : 'numeric'
    return new Intl.DateTimeFormat('en-GB', {
        day: day,
        month: 'short',
        year: 'numeric'
    }).format(date);
}
export const toKwd = (value: number) => {
    return formatNums(value, ",") + " KD";
}
export const toKwdInt = (value: number) => {
    const rounderNumber = Math.round(value);
    return formatNums(rounderNumber, ",") + " KWD";
}

export const HighlightedText = ({ displayedLabel, queryValue }: { displayedLabel: string, queryValue: string }) => {
    // const getDynamicSegments = (label: string, query: string) => {
    //     const result = [];
    //     let currentIndex = 0;

    //     for (let length = 2; length <= query.length; length++) {
    //         let segment = query.slice(0, length);
    //         const index = label.toLowerCase().indexOf(segment.toLowerCase(), currentIndex);

    //         if (index !== -1) {
    //             // Add non-highlighted part before the match
    //             if (index > currentIndex) {
    //                 result.push(label.slice(currentIndex, index));
    //             }

    //             // Add the highlighted part
    //             result.push(
    //                 <span key={index} style={{ backgroundColor: 'yellow' }}>
    //                     {label.slice(index, index + segment.length)}
    //                 </span>
    //             );

    //             // Move currentIndex past the matched segment
    //             currentIndex = index + segment.length;

    //             // Remove the matched part from query and reset length
    //             query = query.slice(length);
    //             length = 0; // reset to allow smaller segments on the next query part
    //         }
    //     }

    //     // Add any remaining text after the last match
    //     if (currentIndex < label.length) {
    //         result.push(label.slice(currentIndex));
    //     }

    //     return result;
    // };

    // const parts = getDynamicSegments(displayedLabel, queryValue);

    // return <>{parts}</>;
    return <Highlight fz={13} highlight={queryValue.split("")}>
        {displayedLabel}
    </Highlight>
};
export const formatNums = (num?: number, sep?: string) => {
    if (!num) return undefined;

    sep = sep || ',';
    return String(num).replace(/\.\d+|\d(?=(?:\d{3})+(?!\d))/g,
        function (a) {
            return a.length === 1 ? a + sep : a
        }
    );
}

export const replaceText = (text: string, obj: any, start = "{{", end = "}}") => {
    return text?.replace(new RegExp(`${start}(.+?)${end}`, "g"), (_, part) => {
        return part?.split(".").reduce((o: any, k: any) => (o || {})[k] ?? k, obj);
    });
}

export function getFirstChildPropValue(
    children: ReactNode,
    propNameCb: (props: any) => string
): string | string[] | undefined {
    let propValue = undefined;

    Children.forEach(children, (element) => {
        if (!isValidElement(element)) return;
        const propName = propNameCb(element.props);
        if (propName in element.props) {
            propValue = element.props[propName];
            return;
        }
    });

    return propValue;
}

export const parseDayFirst = (dateString: string) => {
    var parts = dateString.split("/");
    return new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10));
}



export const englishToArabicNumbers = (stringValue: string) => {
    var id = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
    return stringValue.replace(/[0-9]/g, function (w) {
        return id[+w]
    });
}

const lowerCase = (s: string): string => (s && s[0].toLowerCase() + s.slice(1)) || ""

export const toTimestamp = (strDate: string): Timestamp => {
    const dt = new Date(strDate);
    return Timestamp.fromDate(dt);
};


export const getCustomerFromPaciPersonalData = (data?: PACIData): KYC | null => {

    if (!data?.PersonalData) {
        return null;
    }

    let customer: KYC = {
        paciData: data,
        name_en: "",
        name_ar: "",
        civilID: "",
        mobile: ""
    }

    customer["address"] = (data?.PersonalData?.Address)
    delete data.PersonalData?.Address
    delete data.PersonalData?.GovData


    for (const [keyValue, originalValue] of Object.entries(data?.PersonalData)) {
        const key = keyValue as keyof PersonalData;
        const value: string = originalValue as string;

        if (key === "NationalityAr") {
            continue;
        }
        if (key === "NationalityEn") {
            customer["nationality"] = value
            continue;
        }
        if (key === "FullNameEn") {
            customer["name_en"] = value
            continue;
        }
        if (key === "FullNameAr") {
            customer["name_ar"] = value
            continue;
        }
        if (key === "CardExpiryDate") {
            customer["civilIDExpiryDate"] = toTimestamp(value)
            continue;
        }

        if (key === "MobileNumber") {
            customer["mobile"] = value
            continue;
        }


        const lowercaseKey = lowerCase(keyValue) as keyof (KYC)
        customer = {
            ...customer,

            [lowercaseKey]: value
        }

    }

    return customer as KYC;
};



export const toBase64 = (file: File): Promise<string | ArrayBuffer | null> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});
export const COUNTRY_CODES = {
    AFG: "AF",
    ALA: "AX",
    ALB: "AL",
    DZA: "DZ",
    ASM: "AS",
    AND: "AD",
    AGO: "AO",
    AIA: "AI",
    ATA: "AQ",
    ATG: "AG",
    ARG: "AR",
    ARM: "AM",
    ABW: "AW",
    AUS: "AU",
    AUT: "AT",
    AZE: "AZ",
    BHS: "BS",
    BHR: "BH",
    BGD: "BD",
    BRB: "BB",
    BLR: "BY",
    BEL: "BE",
    BLZ: "BZ",
    BEN: "BJ",
    BMU: "BM",
    BTN: "BT",
    BOL: "BO",
    BES: "BQ",
    BIH: "BA",
    BWA: "BW",
    BVT: "BV",
    BRA: "BR",
    IOT: "IO",
    BRN: "BN",
    BGR: "BG",
    BFA: "BF",
    BDI: "BI",
    CPV: "CV",
    KHM: "KH",
    CMR: "CM",
    CAN: "CA",
    CYM: "KY",
    CAF: "CF",
    TCD: "TD",
    CHL: "CL",
    CHN: "CN",
    CXR: "CX",
    CCK: "CC",
    COL: "CO",
    COM: "KM",
    COG: "CG",
    COD: "CD",
    COK: "CK",
    CRI: "CR",
    CIV: "CI",
    HRV: "HR",
    CUB: "CU",
    CUW: "CW",
    CYP: "CY",
    CZE: "CZ",
    DNK: "DK",
    DJI: "DJ",
    DMA: "DM",
    DOM: "DO",
    ECU: "EC",
    EGY: "EG",
    SLV: "SV",
    GNQ: "GQ",
    ERI: "ER",
    EST: "EE",
    SWZ: "SZ",
    ETH: "ET",
    FLK: "FK",
    FRO: "FO",
    FJI: "FJ",
    FIN: "FI",
    FRA: "FR",
    GUF: "GF",
    PYF: "PF",
    ATF: "TF",
    GAB: "GA",
    GMB: "GM",
    GEO: "GE",
    DEU: "DE",
    GHA: "GH",
    GIB: "GI",
    GRC: "GR",
    GRL: "GL",
    GRD: "GD",
    GLP: "GP",
    GUM: "GU",
    GTM: "GT",
    GGY: "GG",
    GIN: "GN",
    GNB: "GW",
    GUY: "GY",
    HTI: "HT",
    HMD: "HM",
    VAT: "VA",
    HND: "HN",
    HKG: "HK",
    HUN: "HU",
    ISL: "IS",
    IND: "IN",
    IDN: "ID",
    IRN: "IR",
    IRQ: "IQ",
    IRL: "IE",
    IMN: "IM",
    ISR: "IL",
    ITA: "IT",
    JAM: "JM",
    JPN: "JP",
    JEY: "JE",
    JOR: "JO",
    KAZ: "KZ",
    KEN: "KE",
    KIR: "KI",
    PRK: "KP",
    KOR: "KR",
    KWT: "KW",
    KGZ: "KG",
    LAO: "LA",
    LVA: "LV",
    LBN: "LB",
    LSO: "LS",
    LBR: "LR",
    LBY: "LY",
    LIE: "LI",
    LTU: "LT",
    LUX: "LU",
    MAC: "MO",
    MDG: "MG",
    MWI: "MW",
    MYS: "MY",
    MDV: "MV",
    MLI: "ML",
    MLT: "MT",
    MHL: "MH",
    MTQ: "MQ",
    MRT: "MR",
    MUS: "MU",
    MYT: "YT",
    MEX: "MX",
    FSM: "FM",
    MDA: "MD",
    MCO: "MC",
    MNG: "MN",
    MNE: "ME",
    MSR: "MS",
    MAR: "MA",
    MOZ: "MZ",
    MMR: "MM",
    NAM: "NA",
    NRU: "NR",
    NPL: "NP",
    NLD: "NL",
    NCL: "NC",
    NZL: "NZ",
    NIC: "NI",
    NER: "NE",
    NGA: "NG",
    NIU: "NU",
    NFK: "NF",
    MKD: "MK",
    MNP: "MP",
    NOR: "NO",
    OMN: "OM",
    PAK: "PK",
    PLW: "PW",
    PSE: "PS",
    PAN: "PA",
    PNG: "PG",
    PRY: "PY",
    PER: "PE",
    PHL: "PH",
    PCN: "PN",
    POL: "PL",
    PRT: "PT",
    PRI: "PR",
    QAT: "QA",
    REU: "RE",
    ROU: "RO",
    RUS: "RU",
    RWA: "RW",
    BLM: "BL",
    SHN: "SH",
    KNA: "KN",
    LCA: "LC",
    MAF: "MF",
    SPM: "PM",
    VCT: "VC",
    WSM: "WS",
    SMR: "SM",
    STP: "ST",
    SAU: "SA",
    SEN: "SN",
    SRB: "RS",
    SYC: "SC",
    SLE: "SL",
    SGP: "SG",
    SXM: "SX",
    SVK: "SK",
    SVN: "SI",
    SLB: "SB",
    SOM: "SO",
    ZAF: "ZA",
    SGS: "GS",
    SSD: "SS",
    ESP: "ES",
    LKA: "LK",
    SDN: "SD",
    SUR: "SR",
    SJM: "SJ",
    SWE: "SE",
    CHE: "CH",
    SYR: "SY",
    TWN: "TW",
    TJK: "TJ",
    TZA: "TZ",
    THA: "TH",
    TLS: "TL",
    TGO: "TG",
    TKL: "TK",
    TON: "TO",
    TTO: "TT",
    TUN: "TN",
    TUR: "TR",
    TKM: "TM",
    TCA: "TC",
    TUV: "TV",
    UGA: "UG",
    UKR: "UA",
    ARE: "AE",
    GBR: "GB",
    USA: "US",
    UMI: "UM",
    URY: "UY",
    UZB: "UZ",
    VUT: "VU",
    VEN: "VE",
    VNM: "VN",
    VGB: "VG",
    VIR: "VI",
    WLF: "WF",
    ESH: "EH",
    YEM: "YE",
    ZMB: "ZM",
    ZWE: "ZW",
} as any;