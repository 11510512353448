
import { Group, Text, Card, Badge, Button, Image, ActionIcon, Menu } from '@mantine/core';
import { IconQuestionMark, IconWallet, IconX, IconPlus, IconNotebook, IconId, IconBuilding, IconCreditCard, IconCash, IconMoneybag, IconFileTime, IconFile, IconSearch, IconCrop, IconAdjustmentsCheck } from '@tabler/icons-react';
// import { IconBuilding, IconCash, IconCreditCard, IconFile, IconId, IconMoneybag, IconNotebook, IconWallet } from "@tabler/icons-react";

import { StorageReference, updateMetadata } from 'firebase/storage';

import { openConfirmModal, modals } from '@mantine/modals';
import { InfoTable } from './info_table';

import React, { useContext, useState } from 'react';
import { DatePicker } from '@mantine/dates';
import { BaseResult, DeleteFileRequest, DocType, FileTag, CustomFileWithPath } from '../types';
import { MyUserDataContext } from '../contexts';
import { httpsCallable } from 'firebase/functions';
import ImageCropperModal from './image_cropper_modal';
import { FileWithPath, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { uploadToStorage } from '../helpers/uploadToStorage';
import { useFunctions } from '../helpers/firebaseContext';



export const DocTypes: Record<FileTag, DocType> = {
    passport: {
        icon: IconNotebook,
        name: "Passport",
        color: "indigo",
        key: "passport"
    },
    frontOfCivilID: {
        icon: IconId,
        name: "Civil ID (front)",
        color: "green",
        key: "frontOfCivilID",

    },
    backOfCivilID: {
        icon: IconId,
        name: "Civil ID (back)",
        color: "teal",
        key: "backOfCivilID",

    },
    paymentSlip: {
        icon: IconWallet,
        name: "Payment Slip",
        color: "yellow",
        key: "paymentSlip",
    },

    salaryCertificate: {
        icon: IconCash,
        name: "Salary Certificate / Bank Statement",
        color: "blue",
        key: "salaryCertificate",
    },
    proofOfInheritance: {
        icon: IconMoneybag,
        name: "Proof Of Inheritance",
        color: "purple",
        key: "proofOfInheritance",
    },
    proofOfCredit: {
        icon: IconCreditCard,
        name: "Proof Of Credit",
        color: "SkyBlue",
        key: "proofOfCredit",
    },
    proofOfCompanyOwnership: {
        icon: IconBuilding,
        name: "Proof Of Company Ownership",
        color: "YellowGreen",
        key: "proofOfCompanyOwnership",
    },
    amlScreening: {
        icon: IconSearch,
        name: "AML Screening",
        color: "red",
        key: "amlScreening",
    },
    finalSignedAgreement: {
        icon: IconAdjustmentsCheck,
        name: "Final Signed Agreement",
        color: "#50C878",
        key: "finalSignedAgreement",
    },
    other: {
        icon: IconFile,
        name: "Other",
        color: "gray",
        key: "other",
    },
    transferCert: {
        icon: IconFile,
        name: "Transfer Certificate",
        color: "green",
        key: "transferCert",
    },

}





type FileCardProps = {
    linkData: any,
    triggerRefresh: (trigger: boolean) => void
    onChange: () => void
}

export function FileCard(props: Partial<FileCardProps>) {

    const userData = useContext(MyUserDataContext);
    const functions = useFunctions();
    functions.region = "europe-west1";
    const { linkData, triggerRefresh } = props;
    const deleteFile = httpsCallable<DeleteFileRequest, BaseResult>(functions, "deleteFile");
    const [toCropImage, settoCropImage] = useState<CustomFileWithPath | undefined>(undefined);
    const [isImageCropperOpen, setisImageCropperOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const convertImageToFileWithPath = async (imageUrl: string): Promise<File | null> => {
        try {
            // Fetch the image from the provided URL
            const response = await fetch(imageUrl);

            // Get the image data as a Blob
            const blob = await response.blob();

            // Extract the filename from the URL
            const fileName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);

            // Get the MIME type of the image from the Blob
            const fileType = blob.type;

            // Create a File object with the Blob data, filename, and type
            const file = new File([blob], fileName, { type: fileType, lastModified: new Date().getTime() });

            // Return the created File object
            return file;
        } catch (error) {
            // Handle any errors that occur during the conversion process
            console.error('Error converting image:', error);
            // Return null to indicate failure
            return null;
        }
    };


    function handleSave(imageBlob: Blob): void {
        // Check if there's an image to save and if it's intended for cropping
        if (toCropImage && imageBlob) {
            // Set loading state to indicate that the operation is in progress
            setLoading(true);

            // Decode the image path and extract filename and path segments
            const decodedPath = decodeURIComponent(toCropImage.name);
            const pathSegments = decodedPath.split("/");
            const filename = pathSegments.pop()!.split("?alt")[0];
            const path = pathSegments.join("/") + "/";

            // Create a new FileWithPath object representing the cropped image
            const croppedImage: FileWithPath = new File([imageBlob], filename, {
                type: imageBlob.type,
                lastModified: Date.now(),
            }) as FileWithPath;

            // Convert metadata object into tags for Firebase Storage customMetadata
            const tags: Record<string, string> = {};
            for (const [key, value] of Object.entries(meta)) {
                tags[key] = value + "";
            }

            // Upload the cropped image to Firebase Storage
            uploadToStorage(croppedImage, path, tags)
                .finally(() => {
                    // Once upload is complete (or fails), set loading state back to false
                    setLoading(false);

                    // If provided, trigger a refresh  after successful upload
                    if (triggerRefresh) {
                        triggerRefresh(true);
                    }
                })
                .catch((error) => {
                    // Handle any errors that occur during the upload process
                    console.error("Error uploading image:", error);
                });
        }
    }

    /**
     * Handles the click event for cropping an image.
     * Fetches the image from the provided link, converts it to a File object,
     * and opens the image cropper modal if successful.
     */
    const onCropClick = async () => {
        try {
            // Convert the image to a File object
            let file = await convertImageToFileWithPath(linkData.link);

            // If conversion is successful, set the File object for cropping
            // and open the image cropper modal
            if (file) {
                settoCropImage(file);
                setisImageCropperOpen(true);
            }
        } catch (error) {
            // Handle any errors that occur during the conversion process
            console.error('Error cropping image:', error);
        }
    };


    let docTypes = [];
    let expired = false;
    let expiryDate = null;

    let meta = linkData.metadata?.customMetadata;
    console.log("linkData.metadata ===========>> 22222", meta);

    const availableDocTypes: Record<string, DocType> = {
        ...DocTypes,
        unreadable: {
            icon: IconQuestionMark,
            name: "Unreadable",
            color: "red",
            key: "unreadable",
        },
        hasExpiryDate: {
            icon: IconFileTime,
            name: "Has Expiry Date",
            color: "gray",
            key: "hasExpiryDate",
        },
    }


    //add key property to availableDocTypes
    for (let key in availableDocTypes) {
        availableDocTypes[key].key = key;
    }




    //Be careful, custom metadata does not store booleans for some damn reason.
    if (meta) {

        for (let key in meta) {
            if (meta[key] === "true" && key in availableDocTypes) {
                docTypes.push(availableDocTypes[key]);
            }
        }
        if (meta.type === "failed") {
            docTypes.push(availableDocTypes["unreadable"])
        }

        //TODO: which expiry date?  Passport or Civil ID?
        if (expiryDate != null) {
            let d = new Date(expiryDate)
            let now = new Date()
            if (d < now) {
                expired = true;
            }
        }
    }


    let insertableMenuItems = [];

    for (let key in availableDocTypes) {
        if (meta && key in meta) {
            continue;
        }
        let docType = availableDocTypes[key];
        let Icon = docType.icon;
        if (docType === availableDocTypes.hasExpiryDate) {
            insertableMenuItems.push((ref: StorageReference) => {
                return <Menu.Item key={docType.name} leftSection={<Icon size={16} stroke={1.5} color={docType.color} />}
                    onClick={() => {
                        modals.open({
                            title: 'Add Expiry Date',
                            children: <ExpiryDatePicker onSubmit={async (selectedDate: Date) => {
                                if (selectedDate === null) {
                                    return;
                                }
                                await updateMetadata(ref, {
                                    customMetadata: {
                                        [key]: "true",
                                        "expiryDate": selectedDate?.toLocaleDateString("en-GB") ?? "-"
                                    }
                                });
                                triggerRefresh!(true);
                                if (props.onChange) props.onChange();
                                modals.closeAll();
                            }} />
                        });
                    }}
                >
                    <Text>{docType.name}</Text>
                </Menu.Item>
            })

        } else {
            insertableMenuItems.push((ref: StorageReference) => {
                return <Menu.Item key={docType.name} leftSection={<Icon size={16} stroke={1.5} color={docType.color} />}
                    onClick={async () => {

                        openConfirmModal({
                            title: 'Please confirm your action',
                            children: (
                                <Text size="sm">
                                    Are you sure you want to add {<Icon size={16} stroke={1.5} color={docType.color} />} {docType.name} type?
                                </Text>
                            ),
                            labels: { confirm: 'Add', cancel: 'Cancel' },
                            onCancel: () => console.log('Cancel'),
                            onConfirm: async () => {
                                console.log('Confirmed')
                                await updateMetadata(ref, {
                                    customMetadata: {
                                        [key]: "true"
                                    }
                                });
                                //refresh the damn thing.
                                triggerRefresh!(true);
                                if (props.onChange) props.onChange();

                                // await Promise.all([deleteObject(linkData.ref), deleteObject(linkData.thumbRef)]);
                            },
                        });
                    }}>
                    <Text>{docType.name}</Text>
                </Menu.Item>
            })
        }
    }


    return <Card key={linkData.name} shadow="sm" p="lg" radius="md" withBorder>
        <Card.Section>
            <a href={linkData.link} target="_blank" rel="noreferrer">
                <Image
                    src={linkData.thumb}
                    height={160}
                />
            </a>
            {/* 
                Render a button to allow cropping an image if the content type of the link matches any of the specified image MIME types.
                When clicked, fetches the image from the provided link, converts it to a File object, and opens the image cropper modal if successful.
            */}
            {[...IMAGE_MIME_TYPE].includes(linkData?.metadata?.contentType) && <Button
                loading={loading}
                onClick={onCropClick}
                style={
                    {
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        color: 'rgba(255, 255, 255, 0.9)',
                        backgroundColor: 'rgba(0, 0, 0, 0.4)',
                        margin: '8px',
                        padding: '4px',
                    }
                }
            >
                <IconCrop />
            </Button>}

        </Card.Section>

        <Group mt="md" mb="xs">
            {docTypes.map((item: any) => {
                let Icon = item.icon;
                var name = item.name;
                var expiryDate: String | null = linkData.metadata?.customMetadata?.expiryDate;
                console.log(expiryDate);
                if (item.key === "hasExpiryDate" && expiryDate != null) {
                    name = "Expires in " + expiryDate!;
                }
                return <Badge key={item.key} leftSection={<Icon size={12} />} color={item.color} variant="light" rightSection={<ActionIcon size="xs" c="blue" radius="xl" variant="transparent" onClick={() => {
                    openConfirmModal({
                        title: 'Please confirm your action',
                        children: (
                            <Text size="sm">
                                Are you sure you want to remove {<Icon size={16} stroke={1.5} color={item.color} />} {item.name} type?
                            </Text>
                        ),
                        labels: { confirm: 'Remove', cancel: 'Cancel' },
                        onCancel: () => console.log('Cancel'),
                        onConfirm: async () => {
                            console.log('Confirmed')

                            if (item.key === "hasExpiryDate") {
                                await updateMetadata(linkData.ref, {
                                    customMetadata: {
                                        [item.key]: null,
                                        "expiryDate": null
                                    }
                                } as any);
                            } else {
                                await updateMetadata(linkData.ref, {
                                    customMetadata: {
                                        [item.key]: null
                                    }
                                } as any);
                            }
                            //refresh the damn thing.
                            triggerRefresh!(true);

                            if (props.onChange) props.onChange();
                            // await Promise.all([deleteObject(linkData.ref), deleteObject(linkData.thumbRef)]);
                        },
                    });
                }}>
                    <IconX size={12} />
                </ActionIcon>}>
                    {name}
                </Badge>
            })
            }

            {/* <Text fw={500}>{docType.join(", ")}</Text> */}
            {expired &&
                <Badge color="yellow" variant="light">
                    Expired
                </Badge>
            }


            <Menu

                //transitionProps={{ transition: 'pop-top-left' }}
                position="right" withArrow>
                <Menu.Target>
                    <ActionIcon variant="light" radius="lg" ><IconPlus size={16} /></ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                    {
                        insertableMenuItems.map((item) => item(linkData.ref))
                    }
                </Menu.Dropdown>
            </Menu>



        </Group>

        <Text size="sm" c="grape" truncate>
            {linkData.name}
        </Text>
        <Group>
            <Text size="sm" c="dimmed">
                Created {new Date(linkData.metadata?.timeCreated).toLocaleDateString("en-GB")}
            </Text>

            <InfoTable rows={linkData.metadata?.customMetadata} fz="xs" />
        </Group>

        <Button variant="light" color="red" fullWidth mt="md" radius="md" disabled={loading} onClick={async () => {


            openConfirmModal({
                title: 'Please confirm your action',
                children: (
                    <Text size="sm">
                        Are you sure you want to delete ${linkData.name}?
                    </Text>
                ),
                labels: { confirm: 'Delete', cancel: 'Cancel' },
                onCancel: () => console.log('Cancel'),
                onConfirm: async () => {
                    console.log('Confirmed')
                    let token = await userData.getAzureADToken!(userData);
                    let deleteFileRequest: DeleteFileRequest = {
                        token: token,
                        filepath: linkData.ref.fullPath,
                    }
                    await deleteFile(deleteFileRequest);

                    triggerRefresh!(true);
                    if (props.onChange) props.onChange();


                },
            });
        }}>
            Delete
        </Button>
        {false && linkData.metadata?.customMetadata && (Object.keys(linkData.metadata?.customMetadata))?.includes("finalSignedAgreement") && <Button mt={10} radius="md" variant="light"

        >
            Send final copy
        </Button>}
        {isImageCropperOpen && <ImageCropperModal
            imageFile={toCropImage}
            isOpen={isImageCropperOpen}
            onClose={() => { setisImageCropperOpen(false) }}
            onSave={handleSave}
        />}
    </Card>
}
interface ExpiryDatePickerProps {
    onSubmit: (selectedDate: Date) => Promise<void>; // Function to handle submit action
}

const ExpiryDatePicker: React.FC<ExpiryDatePickerProps> = ({ onSubmit }) => {
    const [docExpiryDate, setDocExpiryDate] = useState<Date | null>(null);

    const handleSubmit = async () => {
        if (docExpiryDate === null) {
            return;
        }
        await onSubmit(docExpiryDate); // Call the onSubmit function passed as prop
        modals.closeAll();
    };

    return (
        <>
            <Group justify="center">
                <DatePicker
                    allowDeselect
                    onChange={(date) => { setDocExpiryDate(date); }}
                    value={docExpiryDate}
                    minDate={new Date()}
                    size='md'
                />
            </Group>
            <div style={{ textAlign: 'right' }}>

                <Button style={{ marginLeft: '1rem' }} variant="default" mt="md" onClick={() => {
                    modals.closeAll();
                }} >
                    Cancel
                </Button>
                <Button style={{ marginLeft: '1rem' }} variant="filled" mt="md" onClick={handleSubmit} disabled={docExpiryDate === null}>
                    Add
                </Button>
            </div>
        </>
    );
};