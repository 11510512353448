import { Group, Text, useMantineTheme, Button, useMantineColorScheme, Grid, Divider, Modal } from '@mantine/core';
import { IconUpload, IconPhoto, IconX } from '@tabler/icons-react';
import { Dropzone, DropzoneProps, FileRejection, FileWithPath, IMAGE_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone';
import { useEffect, useState } from 'react';
import { CustomFileWithPath, DocType, FileTag } from '../types';
import FileTagPicker from './file_tag_picker';
import { DateInput } from '@mantine/dates';
import { parseDayFirst } from '../helpers/common';

interface UploadBoxProps extends Omit<DropzoneProps, "onDropAny"> {
  label?: string,
  onDropAny?(files?: CustomFileWithPath[], fileRejections?: FileRejection[], setUploadedFilesFunction?: (values?: (CustomFileWithPath)[]) => void, currentFiles?: CustomFileWithPath[]): void;
  clearFiles: any;

  onRemoveFile?(file: CustomFileWithPath): void;
  onDropWithTag?(files: FileWithPath[], tags: Record<string, string>): void;
  customDocTypes?: Record<string, DocType>,

}

export function UploadBox({ label, onDropAny, clearFiles, onRemoveFile, onDrop, onDropWithTag, ...props }: Partial<UploadBoxProps>) {

  const theme = useMantineTheme();
  // const clearFiles = (props as any).clearFiles
  const [uploadedFiles, setUploadedFiles] = useState<CustomFileWithPath[] | undefined>([]);
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const [fileTagsPickerOpened, setFileTagsPickerOpened] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<FileTag[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<FileWithPath[]>([]);


  const handleRemoveFile = (file: CustomFileWithPath) => {
    console.log("Removing", file);
    setUploadedFiles(uploadedFiles?.filter((uploadedFile) => {
      return uploadedFile !== file
    }))

    if (onRemoveFile) {
      onRemoveFile(file)
    }

  }



  const previews = uploadedFiles?.map((file, index) => {

    return (
      <>
        <Grid >
          <Grid.Col span={{ xs: 8, }} >

            <Text variant="default" truncate="end">
              {file.name}
            </Text>


          </Grid.Col>
          <Grid.Col span={{ xs: 2 }} >

            <Button variant="transparent" c="red" onClick={() => handleRemoveFile(file)}>
              X
            </Button>
          </Grid.Col>
        </Grid>

        <Divider />
      </>
    );
  });


  useEffect(() => {
    if (clearFiles) {
      setUploadedFiles([])
    }
  }, [clearFiles])


  const { colorScheme } = useMantineColorScheme();

  let expiryDate: Date | undefined;

  return (
    <>
      <Dropzone

        onDrop={(files) => {
          if (onDropWithTag) {
            console.log('accepted files', files)
            setSelectedFiles([...files]);
            setFileTagsPickerOpened(true);
          }

        }}
        onReject={(files) => {
          console.log('rejected files', files)
          setRejectedFiles([...rejectedFiles, ...files])
        }}
        maxSize={100 * 1024 ** 2}

        multiple={false}
        accept={["image/png", "image/jpeg", ...PDF_MIME_TYPE]}
        {...props}
        onDropAny={(files, fileRejections) => {
          if (onDropAny && !onDropWithTag) {
            onDropAny(files as CustomFileWithPath[], fileRejections, setUploadedFiles, uploadedFiles);
          }


        }}
      >
        <Group justify="center" gap="xl" style={{ minHeight: 220, pointerEvents: 'none' }}>
          <Dropzone.Accept>
            <IconUpload
              size={50}
              stroke={1.5}
              color={theme.colors[theme.primaryColor][colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              size={50}
              stroke={1.5}
              color={theme.colors.red[colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            {

              ((props as any).label || <>
                <IconPhoto size={50} stroke={1.5} /> "Drag images here or click to select files"
              </>
              )}

          </Dropzone.Idle>


        </Group>
      </Dropzone>
      <div>
        <Text size="xl" inline>

        </Text>
      </div>
      <Modal
        opened={fileTagsPickerOpened}
        onClose={() => setFileTagsPickerOpened(false)}

        title="Select File Tags"
      >
        <>
          <FileTagPicker onFileTagSelect={(tags) => {
            setSelectedTags(tags);


          }}
            title=""
            initialValues={selectedTags}
            customDocTypes={props.customDocTypes}
          />
          <DateInput
            firstDayOfWeek={0}
            locale="en-gb"
            dateParser={parseDayFirst}
            label="Expiry Date"
            name="expiryDate"
            placeholder="Pick date"
            valueFormat="DD/MM/YYYY"
            onChange={(value) => {
              if (value) {
                expiryDate = value;
              }
            }}


          />
          <p>* File will not be uploaded unless tag selected</p>
          <p style={{ color: 'red', fontWeight: 'bold' }}>** Don't upload Payment Slip here</p>
          <Button onClick={() => {


            if (onDropWithTag && selectedTags.length > 0) {
              setFileTagsPickerOpened(false);
              let tags: Record<string, string> = {};
              selectedTags.forEach((tag) => {
                tags[tag] = "true";
              });
              if (expiryDate) {
                tags["hasExpiryDate"] = "true";
                tags["expiryDate"] = expiryDate.toLocaleDateString("en-GB");
              }
              onDropWithTag(selectedFiles, tags);
              setSelectedTags([]);
            }
          }}

            disabled={selectedTags.length <= 0}>Upload</Button>
        </>

      </Modal>
      {previews}
    </>
  );
}