import { MyUserDataContext } from "../contexts"

import { useMantineColorScheme } from '@mantine/core';

import { getAuth, signOut } from "firebase/auth";
import { Avatar, Box, Group, UnstyledButton, Text, useMantineTheme, Menu } from "@mantine/core";
import { IconSettings, IconLogout, IconPassword } from '@tabler/icons-react';
import { IconHeart, } from '@tabler/icons-react';
import UpdateRMPasswordModal from "../routes/settings/UpdateRMPasswordModal";
import { useContext, useState } from "react";
import { RMUser } from "../types";





const Topbar = () => {
   const value = useContext(MyUserDataContext);
   let userClaims = value.firebaseTokenResult?.claims.customClaims;

   const theme = useMantineTheme();

   const { colorScheme } = useMantineColorScheme();

   const [updateRmModal, setUpdateRmModal] = useState<boolean>(false);

   return (
      <Group style={{ height: '100%', width: "100%", }}
         justify="space-between"
         px={20} p="apart">
         <img
            alt="Logo"
            src="/logo.svg" 
            height={80}
            />

         {process.env.REACT_APP_ENV === "development" &&
            <Group p="apart">
               <IconHeart size={18} />
               <Text
                  component="span"
                  ta="center"
                  variant="gradient"
                  gradient={{ from: 'grape', to: 'yellow', deg: 45 }}
                  size="xl"
                  fw={700}
                  style={{ fontFamily: 'Greycliff CF, sans-serif' }}
               >
                  {process.env.NODE_ENV !== "production" ? "Local" : "Test"} environment
               </Text>
               <IconHeart size={18} />

            </Group>
         }


         <Group>
            {/* <Button leftSection={<IconSearch size={16} />}
               onClick={() => openSpotlight()} variant="outline" color="gray" size="lg">
               Search
            </Button> */}


            <>

               <Menu shadow="md" width={250}>
                  <Menu.Target>



                     <Box>
                        <UnstyledButton
                           style={{
                              display: 'block',
                              width: '100%',
                              padding: theme.spacing.xs,
                              borderRadius: theme.radius.sm,
                              color: colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

                              '&:hover': {
                                 backgroundColor:
                                    colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                              },
                           }}
                        >


                           <Group>
                              <Avatar
                                 src={value.user?.photoURL}
                                 radius="xl"
                              />
                              <Box style={{ flex: 1 }}>
                                 <Text size="sm" fw={500}>
                                    {value.user?.displayName}
                                 </Text>

                              </Box>

                              {/* {theme.dir === 'ltr' ? <IconChevronRight size={18} /> : <IconChevronLeft size={18} />} */}
                           </Group>




                        </UnstyledButton>
                     </Box>
                  </Menu.Target>

                  <Menu.Dropdown>
                     <Menu.Label>{value.user?.email}</Menu.Label>
                     <Menu.Item leftSection={<IconSettings size={14} />}>Settings</Menu.Item>

                     {<Menu.Item leftSection={<IconPassword size={14} />}
                        onClick={() => setUpdateRmModal(true)}
                     >

                        Change Password

                     </Menu.Item>}

                     <Menu.Divider />

                     <Menu.Item color="red" leftSection={<IconLogout size={14} />} onClick={() => {
                        const auth = getAuth();

                        signOut(auth).then(() => {
                           // Sign-out successful.
                        }).catch((error) => {
                           // An error happened.
                        });
                     }} >Sign out</Menu.Item>

                  </Menu.Dropdown>
               </Menu>


               <UpdateRMPasswordModal
                  open={updateRmModal}
                  user={{
                     ...value.user
                  } as RMUser} onClose={() => {
                     setUpdateRmModal(false)

                  }} />
            </>


            {/* <ActionIcon
               variant="outline"
               color={dark ? 'yellow' : 'blue'}
               onClick={() => toggleColorScheme()}
               title="Toggle color scheme"
            >
               {dark ? <IconSun size={18} /> : <IconMoonStars size={18} />}
            </ActionIcon> */}



         </Group>
      </Group>
   )
}

export default Topbar