import { Accordion, Alert, Button, Card, Grid, Group, Modal, Image, SimpleGrid, Space, Stack, Text, TextInput, ThemeIcon, Timeline, Title, Checkbox } from '@mantine/core'
import { IconAlertCircle, IconBookUpload, IconEdit, IconEye, IconGitCommit, IconMessageDots, IconPhone, IconSchool, IconStar, IconThumbDown, IconThumbUp, IconTool, IconWreckingBall, } from '@tabler/icons-react';
import EditableInfoCard from '../customers/EditableInfoCard';
import { ActionType, BaseResult, CustomClaims, Customer, DocType, GenericProduct, KYC, OrderRefreshRequest, SendServiceSMSRequest, Service, ServiceActionRequest, ServiceStatusCode, UpdateLink, } from '../../types';
import { collection, doc, DocumentData, DocumentReference, QueryDocumentSnapshot } from 'firebase/firestore';
import ProductLayout from '../../components/products_details_layout';
import { FirebaseError } from 'firebase/app';
import { useContext, useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { useFirestore, useFunctions } from '../../helpers/firebaseContext';
import { AppConfigContext, ConfigContext, MyUserDataContext } from '../../contexts';
import { useForm, UseFormReturnType } from '@mantine/form';
import GeneratePDFModal from '../../components/generate_pdf_modal';
import { servicePaths } from '../../helpers/orderFilesPath';
import { Link } from 'react-router-dom';
import { CustomInfoCard } from '../customers/CustomInfoCard';
import { getCustomerFromPaciPersonalData } from '../../helpers/common';
import { getAddressDetailsEnglish } from '../../helpers/addressHelpers';
import useApprovals from '../../helpers/useApprovals';
import React from 'react';
import { FilesList } from '../../components/files';
import { MessagesSent, refreshWhatsappTemplates } from '../../components/send_sms_modal';

export interface ServiceInfoProps {
    service: Service,
    customerRef: DocumentReference,
    serviceRef: DocumentReference,
    serviceDoc?: QueryDocumentSnapshot<Service>
    // updatedServiceData?: any
    // kycCompleted?: boolean
}

const ServiceInfo = ({ serviceDoc, service, customerRef, serviceRef }: ServiceInfoProps) => {

    const userData = useContext(MyUserDataContext);
    const appConfigContext = useContext(AppConfigContext);
    const config = useContext(ConfigContext);
    let userClaims = userData.firebaseTokenResult?.claims.customClaims as CustomClaims || {};
    const [confirmDialog, setConfirmDialog] = useState<any>(null);
    const [confirming, setConfirming] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [kycData, setKycData] = useState<DocumentData | null>(null);
    const [kycTags, setKycTags] = useState<DocumentData | null>(null);
    const [kycState, setKycState] = useState<DocumentData | undefined>(undefined);
    const [draftActionLoading, setDraftActionLoading] = useState(false);
    const [SMSDocID, setSMSDocID] = useState<string>();
    const [useWhatsapp, setUseWhatsapp] = useState(false);

    const [generatePDFOpen, setGeneratePDFOpen] = useState(false);
    const [confirmDialogError, setConfirmDialogError] = useState<any>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [links, setLinks] = useState([] as any[]);

    const [metaTypes, setMetaTypes] = useState<DocType[]>([]);  //this is for CustomerKYC to set the metatypes of files that exist on the KYC.  That way, we can populate the Generate PDF form properly.


    const db = useFirestore();

    let kycCompleted: boolean = false;


    // let userClaims = userData.firebaseTokenResult?.claims.customClaims as CustomClaims || {};
    const paciData = getCustomerFromPaciPersonalData(service?.paciData);

    const customerData =
        (service?.paciData ? { ...getCustomerFromPaciPersonalData(service?.paciData) } : (service.customerObj)) as (Customer & KYC)


    let actionToTakeLabel: string | null = null;
    let actionToTake: ActionType | null = null;
    let actionColor: string = "";
    let serviceStatusCode = service.tags?.serviceStatus?.code as ServiceStatusCode;

    const functions = useFunctions();
    if (serviceStatusCode === ServiceStatusCode.authorized_signatory_approval) {
        actionToTakeLabel = "Authorized Signatory Approve";
        actionToTake = "authorized_signatory_approve";
        actionColor = "cyan";
    }

    const serviceAction = httpsCallable<ServiceActionRequest, BaseResult>(functions, 'serviceAction');
    const serviceRefresh = httpsCallable<OrderRefreshRequest, BaseResult>(functions, 'serviceRefresh');
    const form: UseFormReturnType<any> = useForm<any>({
        initialValues: {
            updateLink: {
                senderID: "KFHTrade"


            } as UpdateLink,
        }
    })


    const { actions, actionsAfterRejections } = useApprovals(serviceRef);

    useEffect(() => {
        const getData = async () => {

            await refreshWhatsappTemplates(true, form, appConfigContext.apiUrl!, () => { }, false, () => { },
                (config?.serviceTemplates && service.serviceType) ? config?.serviceTemplates[service.serviceType]?.whatsappTemplate : undefined, "updateLink.selectedWhatsappTemplate",
            )


        }
        if (useWhatsapp) { getData() }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useWhatsapp])
    return (
        <div>

            <Space h="xl" />

            <Card shadow="sm" padding="lg" radius="md" withBorder>

                <Group justify="space-between" mt="md" mb="xs">
                    <Button
                        onClick={

                            () => setGeneratePDFOpen(true)

                        }
                    >
                        Generate PDF
                    </Button>
                    <Button color="green" loading={refreshing} type="button" onClick={

                        async () => {

                            setRefreshing(true);
                            await serviceRefresh({ orderID: service.id! });
                            setRefreshing(false);
                            console.warn("Service refreshed!");
                        }

                    }>
                        Refresh Service
                    </Button>

                </Group>
            </Card>
            <Space h="xl" />
            <ProductLayout<Service>
                defaultOpen={["service"]}
                productDoc={serviceDoc}
                product={service}
                customerRef={customerRef}
                productRef={serviceRef}
                showGeneratePDF={false}
                setParentKycData={setKycData}
                setParentKycTags={setKycTags}
                setParentKycState={setKycState}
                metaTypes={metaTypes}
                setMetaTypes={setMetaTypes}
            >
                <Accordion.Item key="service" value="service">
                    <Accordion.Control >
                        <Group p="apart">
                            <ThemeIcon
                                variant="gradient"
                                size="xl"
                                aria-label="Gradient action icon"
                                gradient={{ from: 'red', to: 'grape', deg: 180 }}
                            >
                                <IconTool size={30} />
                            </ThemeIcon>
                            <Text
                                component="span"
                                ta="center"
                                variant="gradient"
                                gradient={{ from: 'red', to: 'grape', deg: 180 }}
                                size="xl"
                                fw={700}
                                style={{ fontFamily: 'Greycliff CF, sans-serif' }}
                            >
                                Service Information
                            </Text>
                        </Group>
                    </Accordion.Control>
                    <Space h={"xl"} />

                    <Accordion.Panel>


                        <SimpleGrid cols={3}>

                            <EditableInfoCard
                                title="Service Information"
                                color="teal"
                                itemDoc={serviceDoc}
                                updatedItemData={{}}
                                icon={<IconWreckingBall size={16} />}
                                list={[

                                    {
                                        label: 'Date',
                                        value: service.created?.toDate().toDateString()
                                    },


                                    {

                                        label: 'Type',
                                        value: service.serviceType
                                    },
                                    {
                                        label: "KYC Status",
                                        value:

                                            <Link to={`/customers/${customerRef?.id}`}>

                                                {kycCompleted ? <Text c="green">{kycTags?.title}</Text> : <Text c="yellow">{kycState?.status || "No KYC"}</Text>}
                                            </Link>

                                    },

                                    {

                                        label: 'Rm',
                                        value: service?.rm?.email
                                    },





                                ]}

                            />


                            <CustomInfoCard
                                title="Client Information"
                                color="yellow"
                                icon={<IconPhone size={16} />}
                                list={[
                                    {
                                        label: "Name (en)",
                                        value: customerData?.name_en,
                                    },
                                    {
                                        label: "Name (ar)",
                                        value: customerData?.name_ar,
                                    },
                                    {
                                        label: "Commercial Register",
                                        value: customerData?.commercial_register,
                                    },

                                    {
                                        label: "Civil ID",
                                        value: customerData?.civilID,
                                    },
                                    {
                                        label: "Civil ID Expiry",
                                        value: paciData?.civilIDExpiryDate?.toDate().toLocaleDateString("en-gb")
                                    },

                                    {
                                        label: "Mobile",
                                        value: customerData?.mobile,
                                    },
                                    {
                                        label: "Nationality",
                                        value: customerData?.nationality,
                                    },
                                    {
                                        label: "Gender",
                                        value: customerData?.gender,
                                    },
                                    {
                                        label: "PACI Sponsor",
                                        value: service.paciData?.PersonalData?.GovData?.SponsorName || undefined
                                    },
                                    {
                                        label: "PACI Mobile",
                                        value: service.paciData?.PersonalData?.MobileNumber || undefined
                                    },
                                    {
                                        label: "PACI Email",
                                        value: service.paciData?.PersonalData?.EmailAddress || undefined,
                                        noCapitalize: true,
                                    },

                                    {
                                        label: "Address (en)",
                                        value: paciData?.address?.DetailsEnglish,
                                    },

                                    {
                                        label: "Address (ar)",
                                        value: paciData?.address?.DetailsArabic,
                                    },
                                    {
                                        label: "Address",
                                        value: paciData?.address ? getAddressDetailsEnglish(paciData?.address) : null,
                                    },
                                    // ...Object.keys(data?.address).map((key) => {return {label: key, value: data?.address[key]}} )
                                ]}
                            />



                            {service.signature &&


                                <CustomInfoCard
                                    title="Signature"
                                    color="violet"
                                    icon={
                                        <IconSchool size={16} />
                                    }
                                    list={[

                                        {
                                            label: '',
                                            value: service.signature && <Image
                                                fit="contain"
                                                // w="auto"
                                                // h={150}



                                                src={`data:image/png;base64,${service.signature}`}
                                            />
                                        },


                                    ]}
                                />
                            }


                        </SimpleGrid>
                        {
                            <>
                                <Title order={3}>Files</Title>
                                <FilesList
                                    setMetaTypes={setMetaTypes}
                                    path={`services/${service.id}/`}
                                    links={links}
                                    setLinks={setLinks}
                                    showUploader={false}
                                />
                            </>
                        }

                        {serviceStatusCode === ServiceStatusCode.need_signature &&

                            <Grid mt={20}>
                                <Grid.Col span={4}>

                                    <Button type="button" name="send_sms"
                                        loading={draftActionLoading}
                                        onClick={async () => {
                                            setDraftActionLoading(true);


                                            const sendServiceSMS = httpsCallable<SendServiceSMSRequest, BaseResult>(functions, "sendServiceSMS");


                                            //generate a doc ID
                                            const docID = doc(collection(db, "sms")).id;
                                            setSMSDocID(docID);
                                            //set type to submit because we want the user to create an entirely new KYC
                                            await sendServiceSMS({
                                                service: service,
                                                smsDocID: docID,
                                                token: await userData.getAzureADToken!(userData),

                                                type: useWhatsapp ? "whatsapp" : "sms"

                                            });



                                            setDraftActionLoading(false);

                                            // notifications.show({
                                            //     title: 'SMS Sent',
                                            //     message: 'Client should receive an SMS shortly',
                                            // })



                                            //call function to send SMS
                                        }}>
                                        Re-send Service SMS
                                    </Button>

                                </Grid.Col>
                                <Grid.Col span={6}>

                                    {(userClaims.admin || userClaims.cr) && <Checkbox
                                        checked={useWhatsapp}
                                        label="send through whatsapp"
                                        onChange={(e) => {
                                            setUseWhatsapp(e.target.checked)

                                            setSMSDocID(undefined);

                                        }}
                                    />}

                                </Grid.Col>
                                <Grid.Col span={6}>
                                    {(SMSDocID) &&

                                        <MessagesSent docID={SMSDocID} handleFailedMessages={() => {

                                            const handleOrderSMS = async () => {

                                                const sendServiceSMS = httpsCallable<SendServiceSMSRequest, BaseResult>(functions, "sendServiceSMS");


                                                //generate a doc ID
                                                const docID = doc(collection(db, "sms")).id;
                                                setSMSDocID(docID);

                                                //set type to submit because we want the user to create an entirely new KYC
                                                await sendServiceSMS({
                                                    service: service!,
                                                    smsDocID: docID,
                                                    token: await userData.getAzureADToken!(userData)
                                                });
                                            }
                                            handleOrderSMS();
                                        }} />

                                    }
                                </Grid.Col>

                                {/* {deleteButton} */}

                            </Grid>
                        }


                        {actions && actions.length > 0 &&
                            <Grid mt={20}>
                                <Grid.Col span={12}>



                                    <Title order={3}>Actions</Title>
                                </Grid.Col>

                                <Grid.Col span={12}>

                                    {/* <Container size="xl"> */}
                                    <Timeline active={100} bulletSize={24} lineWidth={2} reverseActive>

                                        {

                                            actions.map((action, index: number) => {
                                                // console.log(action.id, index);
                                                let verb = "Unknown";
                                                let color = "gray";
                                                let icon = <IconMessageDots size={12} />;


                                                if (action.type === "reject") {
                                                    verb = "Rejected";
                                                    color = "red";
                                                    icon = <IconThumbDown size={12} />;
                                                }
                                                else if (action.type === "needs_update") {
                                                    verb = "Needs update";
                                                    color = "yellow";
                                                    icon = <IconGitCommit size={12} />;
                                                }
                                                else if (action.type === "review") {
                                                    verb = "Reviewed";
                                                    color = "cyan";
                                                    icon = <IconEye size={12} />;
                                                }
                                                else if (action.type === "operations_approve") {
                                                    verb = "Operations Approved";
                                                    color = "indigo";
                                                    icon = <IconEye size={12} />;
                                                }
                                                else if (action.type === "authorized_signatory_approve") {
                                                    verb = "Authorized Signatory Approved";
                                                    color = "indigo";
                                                    icon = <IconEye size={12} />;
                                                }
                                                else if (action.type === "approve") {
                                                    verb = "Approved";
                                                    color = "green";
                                                    icon = <IconThumbUp size={12} />;
                                                    if (action.level === "executive") {
                                                        verb = "Approved";
                                                        color = "grape";
                                                        icon = <IconStar size={12} />;

                                                    }
                                                }
                                                else if (action.type === "update") {
                                                    verb = "Updated";
                                                    color = "blue";
                                                    icon = <IconEdit size={12} />;
                                                }
                                                else if (action.type === "upload_docs") {
                                                    verb = "Uploaded documents";
                                                    color = "blue";
                                                    icon = <IconBookUpload size={12} />;
                                                }

                                                return <Timeline.Item bullet={icon} title={verb} color={color} key={index}>
                                                    <Text c="dimmed" size="sm">{`${verb} by ${action.name}`}</Text>
                                                    {action.comments && <Text c="violet" size="sm">{action.comments}</Text>}
                                                    <Text size="xs" mt={4}>{new Date(action.date.seconds * 1000).toLocaleString("en-GB", { hour12: true })}</Text>
                                                </Timeline.Item>

                                            }).reverse()  /* reverse so the latest action is at the top */
                                        }
                                    </Timeline>
                                </Grid.Col>

                                {/* </Container> */}
                            </Grid>
                        }

                        <Group mt={20}>


                            {actionToTake &&
                                <Button disabled={

                                    false
                                    //     (!kycCompleted
                                    //         && !isOrderAndKycSameState
                                    //         && !isKycInApproveAndOrderInReview)
                                    // || ((serviceStatusCode === "review"
                                    //     || serviceStatusCode === "approval")
                                    //     && !allFilesTagged
                                    // )
                                }
                                    color={actionColor} type="button" name="approve" onClick={async () => {


                                        setConfirmDialog({
                                            title: "Confirm",
                                            action: actionToTakeLabel,
                                            description: `${actionToTakeLabel} ${service.customerObj?.name_en}'s service?`,
                                            buttonColor: actionColor,
                                            callback: async () => {

                                                //The action needs Microzift's accessToken so I can verify groups from the server side.
                                                let token = await userData.getAzureADToken!(userData);

                                                if (!token) {
                                                    throw new FirebaseError("no_microsoft_token", "No Microsoft token.  Try refreshing");
                                                }




                                                await serviceAction({ action: actionToTake as ActionType, serviceID: service.id!, customerID: customerRef.id, token: token });
                                                setRefreshing(true);
                                                await serviceRefresh({ orderID: service.id! });
                                                setRefreshing(false);
                                                console.warn("Order refreshed!");
                                            }
                                        })
                                    }}>
                                    {actionToTakeLabel} {!kycState ? "(No valid KYC)" : ""}
                                </Button>
                            }


                            {/* <Button disabled={disableOrderRejectAction} color="red" onClick={async () => {
                                console.log("Rejecting Order!!!");
                                setIsRejectingOrder(true);
                                setRejectionOpened(true)
                            }} >Reject</Button> */}

                        </Group>

                        <Modal
                            opened={confirmDialog != null}
                            onClose={() => { setConfirmDialog(null); setConfirmDialogError(null); }}
                            title={<Title order={3}>{confirmDialog?.title}</Title>}
                        >

                            <form onSubmit={form.onSubmit(async (values) => {
                                setConfirmDialogError(null);
                                setConfirming(true);
                                try {
                                    await confirmDialog?.callback(values);
                                    setConfirmDialog(null);
                                } catch (error: any) {
                                    setConfirmDialogError(error.message);
                                }
                                setConfirming(false);

                            })}>
                                <Stack>

                                    <Text fw={300}>{confirmDialog?.description}</Text>
                                    {/* <Space h="xl"/> */}
                                    {confirmDialog?.textField && <><Text>{confirmDialog?.textFieldDescription}</Text><TextInput  {...form.getInputProps(confirmDialog.textField)} /></>}
                                    <Button color={confirmDialog?.buttonColor} type="submit" loading={confirming}>{confirmDialog?.action}</Button>
                                    {confirmDialogError && <Alert icon={<IconAlertCircle size={16} />} title="Whoops!" c="red">{confirmDialogError}</Alert>}
                                </Stack>
                            </form>
                        </Modal>
                    </Accordion.Panel>
                </Accordion.Item>

            </ProductLayout>


            <GeneratePDFModal
                getCustomerDataKey={"customerObj"}
                // generatedType={"order"}
                objID={service.id}
                rows={[{


                    ...service,
                    ...kycData,
                    customerID: kycData?.civilID,
                    id: service.id,
                    iban: service?.iban,
                    documentActions: actionsAfterRejections,

                    address: {
                        ...kycData?.address, "DetailsEnglish":
                            kycData?.address ? getAddressDetailsEnglish(kycData?.address)
                                : "",
                    },

                }]}



                // getCustomerDataKey={null}
                documentsToFetch={
                    [{
                        name: "services",
                        subCollectionAccessorName: "id",
                        documentsToAddFunction: (serviceObj: GenericProduct) => {
                            return (serviceObj?.serviceType) ? servicePaths[serviceObj?.serviceType] : []
                        },
                        type: "collectionGroup"

                    }]
                }

                metaTypes={metaTypes}

                opened={generatePDFOpen}
                setOpened={setGeneratePDFOpen}


            />

        </div >
    )
}

export default ServiceInfo