import { ActionIcon, Badge, Group, Paper, Space, Text, ThemeIcon, Title } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCheck, IconEdit, IconX } from "@tabler/icons-react";
import { ReactNode, useContext } from "react";
import { CustomClaims, ItemInfoEditedObj, UserDataType, UpdatedDataType } from "../../types";
import { MyUserDataContext } from "../../contexts";
// import TooltipOrderInfo from "./TooltipOrderInfo";
import { QueryDocumentSnapshot, Timestamp } from "firebase/firestore";
import { ObjectArrayType } from "./customer_kyc";
import ItemInfoEditForm from "./ItemInfoEditForm";
import TooltipItemInfo from "./TooltipItemInfo";
import { useFirestore } from "../../helpers/firebaseContext";



export type ComponentType = "MultiSelect" | "Select" | "TextInput" | "NumberInput" | "Timestamp"

interface EditableItemListItem<T> {

    label: string | ReactNode;
    value: string | ReactNode;
    noCapitalize?: boolean,
    editable?: boolean,
    dataOptions?: ObjectArrayType[] | string[],
    fieldName?: keyof T,
    componentType?: ComponentType,
    rightSection?: ReactNode;
    handleChange?: (value: string | number | Timestamp | null) => { [key: string]: any };

}
interface EditableInfoCardProps<T> {
    title: string | Element | ReactNode;
    updatedItemData: ItemInfoEditedObj<T>,
    list: EditableItemListItem<T>[];
    icon: ReactNode;
    color: string;
    itemDoc?: QueryDocumentSnapshot<T>;

}

function EditableInfoCard<T extends { id?: string | undefined; updated_data?: UpdatedDataType[] | undefined; }>({ title, list, icon, color, itemDoc, updatedItemData, ...props }: EditableInfoCardProps<T>) {
    //can also convert arrays by recursively calling this function.  Can break in amazing fashion if array contents are not strings.
    const userContext: UserDataType = useContext(MyUserDataContext);
    let userClaims = userContext.firebaseTokenResult?.claims.customClaims as CustomClaims || {};



    const db = useFirestore();



    const renderValue = (item: EditableItemListItem<T>, key: number) => {
        let value: ReactNode | string;
        let infoTitle = <Badge
            color="dimmed"
            // justify="center"
            tt={"capitalize"}
            fw={"normal"}
            fz={"sm"}
            variant='transparent'
            fullWidth
        >
            {item.label}
        </Badge>
        if (itemDoc && item.editable

            // && !(kyc?.state?.status_code === "valid" || kyc?.state?.status_code === "rejected")
        ) {
            infoTitle = <TooltipItemInfo<T> title={item.label} data={updatedItemData} keyValue={item.fieldName!} />

            value = <Text tt="capitalize" className="showBtnOnHover" fw={500} size="sm" ta="right">
                <>
                    {item.value || "N/A"}


                    <ActionIcon variant="white" ml={4} aria-label="Edit average annual income" onClick={() => {


                        modals.open({
                            withCloseButton: false,
                            closeOnClickOutside: false,
                            title: 'Edit ' + item.label,
                            children: (

                                <ItemInfoEditForm<T>
                                    itemDoc={itemDoc}
                                    user={userContext.user!}
                                    componentType={item.componentType!}
                                    rightSection={item.rightSection}
                                    fieldName={item.fieldName! as string}
                                    db={db}
                                    handleChange={item.handleChange}
                                    data={item.dataOptions!}
                                    value={(itemDoc.data()?.[item.fieldName!]) as string | number | string[]}
                                />
                            ),
                        });

                    }}>
                        <IconEdit style={{ width: '70%', height: '70%' }} stroke={1.5} />
                    </ActionIcon>
                </>
            </Text>
        }
        else {        //string?  format it properly.
            if (typeof item.value === "string") {
                value = <Text tt={item.noCapitalize ? undefined : "capitalize"} fw={500} size="sm" ta="right">
                    {item.noCapitalize ? item.value : (item.value)}
                </Text>
            }
            //boolean?  show a checkmark or an X
            else if (typeof item.value === "boolean") {
                value = item.value ? (
                    <ThemeIcon radius="xl" size="xs" color="green">
                        {" "}
                        <IconCheck size={12} />{" "}
                    </ThemeIcon>
                ) : (
                    <ThemeIcon radius="xl" size="xs" color="red">
                        <IconX size={12} />
                    </ThemeIcon>
                );
            }
            //neither?  Maybe it's just a JSX element.  show the damn thing anyway.
            else value = item.value || "N/A";
        }


        return (
            <Group key={key} justify="space-between">
                <Text tt="capitalize" size="sm" c="dimmed">
                    {infoTitle}
                </Text>
                {value}
            </Group>
        );
    }


    return (
        <Paper shadow="xs" p="md">
            <Group>
                <ThemeIcon color={color} variant="light">
                    {icon}
                </ThemeIcon>
                <Title order={5}>
                </Title>
                {title as ReactNode}
            </Group>
            <Space h="xs" />
            {/* <Grid gutter="xs"> */}
            {list
                .filter((item) => {
                    if (!item.editable)
                        return item.value || item.editable;
                    return item
                })
                .map((item, idx) => {
                    let key = idx;

                    return renderValue(item, key);
                })}
            {/* </Grid> */}
        </Paper>
    );
};

export default EditableInfoCard;