import React, { useEffect, useMemo, useRef, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { BsPlusCircle } from "react-icons/bs";

import { Button, Card, Grid, Group, Space, ThemeIcon, Title, Tooltip, } from "@mantine/core";
// import KYCStatusFilter from "../../components/KYCStatusFilter";



import { IconEye } from "@tabler/icons-react";

import { RefreshButton, RefreshButtonRef } from "../../components/customer_instant_search";
import ServicesInstantSearch from "./services_instant_search";
import { Service } from "../../types";



interface ActionsComponentProps {
  serviceObj: Service;
}


const RenderActions = ({ serviceObj }: ActionsComponentProps) => {
  // if (serviceObj.created && !(serviceObj.created instanceof Timestamp)) {
  //   serviceObj.created = new Timestamp(serviceObj.created as any, 0);
  // }


  const navigate = useNavigate();

  return <Group>


    <ThemeIcon color="blue" variant="light" onClick={() => {
      navigate("/services/" + serviceObj.id, { state: serviceObj })
    }}><IconEye size={16} /></ThemeIcon>


  </Group>

}
const ServicesList = () => {



  const [selectedRows, setSelectedRows] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const { refreshServicesIn } = location.state || {};


  const refreshRef = useRef<RefreshButtonRef | null>(null);

  const handleExternalRefresh = () => {
    if (refreshRef.current) {
      refreshRef.current.refresh();
    }
  };


  useEffect(() => {
    if (refreshServicesIn) {
      const timer = setTimeout(() => {
        // Action to perform after delay
        console.log('Refreshing services...');
        handleExternalRefresh();
        // Place your code here to refresh services or any other intended action
      }, refreshServicesIn);

      // Clean up the timer when the component unmounts or if the value of refreshServicesIn changes
      return () => clearTimeout(timer);
    }
  }, [refreshServicesIn]); // Dependency array, re-run effect if refreshServicesIn changes



  const searchableServices = useMemo(() => <ServicesInstantSearch
    setSelectedRows={setSelectedRows}
    selectable={true}
    selectedRows={selectedRows}
    renderTags={true}
    navigateDetails={(obj: any) => {


      // return navigate("/services/" + obj.id)



    }
    }
    ActionsComponent={RenderActions}
    indexName={process.env.REACT_APP_TYPESENSE_SERVICES_COLLECTION!}

    additionalChildren={<RefreshButton ref={refreshRef} />}




  />,
    // eslint-disable-next-line 
    [selectedRows]);



  return (
    <>
      <Group justify="space-between">
        <Title>Services</Title>
        <Button
          leftSection={<BsPlusCircle />}
          onClick={() => {
            navigate("/add_service");
          }}
        >
          {" "}
          Add service{" "}
        </Button>
      </Group>
      <Space h="xl" />
      {/* <Card shadow="sm" p="lg" radius="md" withBservice> */}
      <Card shadow="sm" p="lg" radius="md" withBorder>
        <Grid justify="space-between">
          <Grid.Col>

          </Grid.Col>
          <Grid.Col>
            {searchableServices}
          </Grid.Col>
        </Grid>
      </Card>



    </>
  );
};


const Services = () => {
  return (
    <main style={{ padding: "1rem 0" }}>
      <ServicesList />

    </main>
  );
};

export default Services;
