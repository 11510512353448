import { Badge, Card, Text, Tooltip } from '@mantine/core'
import { IconAlertCircle } from '@tabler/icons-react';
import { ReactNode } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { EditableObjData, ItemInfoEditedObj, } from '../../types';
import { Timestamp } from 'firebase/firestore';

interface TooltipItemInfoProps<T> {
    title: string | ReactNode
    keyValue: keyof T
    data: ItemInfoEditedObj<T>,

}

export function printNestedWithOthersAttribute<T, secondGenericType>(key: keyof T, innerKey: string, dataObj: T, intl: IntlShape): string | number {
    let value: string[] | string | undefined | number = []
    if (dataObj[key] && innerKey && ((dataObj[key] as secondGenericType)[innerKey as keyof secondGenericType])) {

        const actualValue = (((dataObj[key] as secondGenericType)[innerKey as keyof secondGenericType]));

        if (typeof actualValue === 'number')
            return actualValue;
        if (actualValue instanceof Timestamp) {
            return (((dataObj[key] as secondGenericType)[innerKey as keyof secondGenericType]) as Timestamp)?.toDate().toLocaleDateString("en-gb") ?? 'N/A';
        }
        if (typeof actualValue === 'string') {
            value = (((dataObj[key] as secondGenericType)[innerKey as keyof secondGenericType]) as string)?.split(",");

        }
        else {
            value = actualValue as string[];
        }
        console.log("actualValue STEING  ====>>>", value);
        //add translation to string values
        value = value?.map((valueObj) => {
            if (typeof valueObj && valueObj !== "other") {
                return (intl.formatMessage({ id: valueObj })) as string
            }
            return valueObj
        });

        console.log("actualValue VALUE ARRR  ====>>>", value);


        //does it have "other" in it?
        const otherIndex = value.indexOf("other")
        if (otherIndex !== -1) {
            //get the other value
            let otherValue: string | null = null;
            if (dataObj[(key as string + "_other_field") as keyof T])
                otherValue = ((dataObj[(key as string + "_other_field") as keyof T] as secondGenericType)[innerKey as keyof secondGenericType]) as string | null
            //if it's not empty, add it to the array
            if (otherValue) {

                value[otherIndex] = `other (${otherValue})`

            }
        }

        return value.join(",");
    }
    return ""

}


function TooltipItemInfo<T>({ data, keyValue, title, }: TooltipItemInfoProps<T>) {
    const intl = useIntl();



    return (

        <Badge
            color="dimmed"
            variant='transparent'
            fullWidth
            tt={"capitalize"}
            fw={"normal"}
            fz={"sm"}

            rightSection={
                data && data[keyValue] ? <Tooltip
                    bg={"white"}
                    label={
                        data[keyValue] && <Card withBorder>


                            <Text c="black" >Previous Value : <Text span c="blue" inherit>{printNestedWithOthersAttribute<ItemInfoEditedObj<T>, EditableObjData>(keyValue, "previousValue", data, intl)}</Text> </Text>
                            <Text c="black" >Current Value : <Text span c="blue" inherit><>
                                {printNestedWithOthersAttribute<ItemInfoEditedObj<T>, EditableObjData>(keyValue, "currentValue", data, intl)}
                            </></Text> </Text>
                            <Text c="black" >Updated By : <Text span c="blue" inherit>{data[keyValue]?.updatedBy}</Text> </Text>
                            <Text c="black" >Updated On : <Text span c="blue" inherit>{data[keyValue]?.updatedOn?.toDate().toDateString()}</Text> </Text>
                            <Text c="black" >Reason: <Text span c="blue" inherit>{data[keyValue]?.reason}</Text> </Text>

                        </Card>
                    }>
                    <IconAlertCircle
                        size={"15px"}
                        color='orange' />
                </Tooltip> : <></>
            }
        >
            <Text
            >

                {title}
            </Text>

        </Badge>

    )


}

export default TooltipItemInfo